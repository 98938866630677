import request from '@/request/request.js';

export const GetLoginUserInfos = {
  //获取用户
  method: 'post',
  module: 'common',
  address: 'api/Customer/GetLoginUserInfos',
};

// 获取奖励规则
export function GetSpecifiedGlobalConfig(data) {
  const options = {
    method: 'post',
    module: 'common',
    address: 'api/enterprise/config/GetEnterpriseConfigs',
  };
  return request({ apiModule: options, data });
}

export const GetCostTypeList = {
  //获取费用类型列表
  method: 'get',
  module: 'expensecontrol',
  address: 'Expense/GetCostTypeList',
};

export const IsPasswordExpired = {
  //获取费用类型列表
  method: 'post',
  module: 'b2b',
  address: 'Customer/IsPasswordExpired',
};

export const LoginOut = {
  //退出登录
  method: 'post',
  module: 'b2b',
  address: 'Customer/LoginOut',
};
